import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";

import GooglePlayButton from "../../../components/common/GooglePlayButton";
import {aggGalleryUrl} from "../../../helpers/UserUrl";
import {switchToOld} from "../../../helpers/SwithDesignPost";
import Logo from '../../../assets/travelgirls-logo.svg';
import TfLogoGray from '../../../assets/logo-travelfriend.svg';
import classes from './Footer.module.scss';
import {flavor, isTravelGirls} from "../../../config/Flavor";

const Footer = () => {
    const user = useSelector(state => state.auth.user);
    const enabledSwitch = (isTravelGirls && user && user.additional.enabledSwitchToOldDesign);

    return (
        <div className={classes.Footer}>
                <div className={`${classes.FooterBackground} ${!isTravelGirls ? classes.emptyBackground : ''}`}>

                <div className={`${classes.FooterInner} ${!isTravelGirls ? classes.emptyBackground : ''}`}>
                    <div style={{display: 'flex', alignItems: 'top'}}>
                        <ul>
                            <li className={classes.title}>About</li>
                            {isTravelGirls && <li><NavLink to="/about-us">About {flavor.name}</NavLink></li>}
                            <li><NavLink to={aggGalleryUrl(user)}>Browse members</NavLink></li>
                            <li><NavLink to="/trips">Trips</NavLink></li>
                            {isTravelGirls && <li><NavLink to="/sugar-daddy-dating">Popular cities</NavLink></li>}
                            {enabledSwitch && <li><button onClick={switchToOld}>Old design</button></li>}
                        </ul>
                        <ul>
                            <li className={classes.title}>Get help</li>
                            {isTravelGirls && <li><NavLink to="/faq">Need help? FAQs.</NavLink></li>}
                            <li><NavLink to="/travel-tips">Travel tips</NavLink></li>
                            <li><NavLink to="/support">Contact us</NavLink></li>
                        </ul>
                        <div className={classes.Logos}>
                                    <div style={{marginBottom: '26px'}}>
                                        {isTravelGirls?
                                            <img src={Logo} alt="Travelgirls logo" />:
                                            <img src={TfLogoGray} alt="Travelfriend logo" style={{height:20}}/>
                                        }
                                    </div>
                            <GooglePlayButton style={{width: '133px'}}/>
                        </div>
                    </div>
                    <div className={classes.Secure}>
                        <i className={['tg-icon', 'icon-lock-2', classes.Lock].join(' ')} />
                        <span className={classes.Text}>100% secure</span>
                        <i className={['tg-icon', 'icon-visa', classes.CreditCards].join(' ')} />
                        <i className={['tg-icon', 'icon-master-card', classes.CreditCards].join(' ')} />
                    </div>
                    <div className={classes.AllRights}>
                        {isTravelGirls
                            ? <>All rights are reserved. TM Solutions Limited 2010 - {new Date().getFullYear()}
                                © {flavor.domainName}</>
                            : <>All rights are reserved. ©{flavor.domainName}</>}
                        &nbsp;&nbsp;·&nbsp;
                        <NavLink to="/terms-and-conditions">Terms and Conditions</NavLink>&nbsp;&nbsp;·&nbsp;&nbsp;
                        <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                        &nbsp;&nbsp;·&nbsp;&nbsp;
                        {!isTravelGirls &&
                            <div>Compassfriends Limited. Suite 1, Sterling building, Enrico Mizzi street, Ta'xbiex,
                                Malta. C-79357</div>}
                    </div>
                </div>
                </div>
        </div>
    )
}

export default Footer;
