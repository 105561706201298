import {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";


import {getMessageDraft, saveMessageDraft} from "../../helpers/MessageDraft";
import classes from './SendMessage.module.scss';
import aggPhoto, {getMainPhotoInMemberProfile} from '../../helpers/Photos';
import imgVerified from '../../assets/verified-green.svg';
import imgConversationIcon from '../../assets/conversation-icon.png';
import imgVerificationBack from '../../assets/vip-conversations-back.png'
import vipIcon from '../../assets/vip-user-mobile.png';
import {modalActions} from '../../store/modal';
import {sendMessage} from "../../store/conversation";
import {openedUserActions} from "../../store/openedUser";


const SendMessage = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const modalUser = useSelector(state => state.modal.data.user);

    const [content, setContent] = useState(getMessageDraft(modalUser.id));
    const [isSent, setIsSent]  = useState(false);
    const [showVipPromotion, setShowVipPromotion]  = useState(false);

    useEffect(() => {
        saveMessageDraft(modalUser.id, content);
    }, [content]);

    const u = modalUser;
    if (!modalUser) {
        return (<div></div>);
    }

    const send = () => {
        if (content.length === 0) {
            return;
        }

        dispatch(sendMessage(user.id, modalUser.id, content));
        const newUser = {
             ...modalUser,
             viewLayer: {
                ...modalUser.viewLayer,
                sendMessageCallToAction: 'conversation'
            }
        };
        dispatch(openedUserActions.set({user: newUser}));
        saveMessageDraft(modalUser.id, '');
        setIsSent(true);
    }

    const showVipPromotionHandler = () => {
        setShowVipPromotion(true);
    }

    if (showVipPromotion === true) {
        return <VipPromotion user={user} modalUser={modalUser} />
    }

    return (
        <div className={classes.Area}>
            <img src={getMainPhotoInMemberProfile(u, 100)} className={classes.UserImage} alt="User profile" />
            <div className={classes.Name}>
                {modalUser.name}
                {modalUser.sex === 'female' && modalUser.isVerified ? <img className={classes.Verified} src={imgVerified} alt=""/> : null}
            </div>
            {isSent ? <MessageWasSent user={user} onShowVipPromotion={showVipPromotionHandler} /> : (
                <Fragment>
                    <div className={classes.Text}>Write a quick message to catch {modalUser.sex === 'female' ? 'her' : 'his'} interest</div>
                        <div className={classes.AreaActions}>
                        <textarea
                            className={classes.Textarea}
                            placeholder={'Your message...'}
                            value={content}
                            onChange={(e) => setContent(e.target.value)} />
                        <div className={classes.Actions}>
                            <button className={classes.SendArea} onClick={send}>
                                Send <i className="tg-icon icon-send"></i>
                            </button>
                        </div>
                    </div>
                </Fragment>)}
        </div>
    );
};

const MessageWasSent = ({user, onShowVipPromotion}) => (
    <>
        <div className={classes.Text}>Message was sent successfully!</div>
        {user.additional.prices.vipUpgradeEnabled &&
            <>
                <div className={classes.IconConversation}><img src={imgConversationIcon} alt="conversation icon" /></div>
                <div className={classes.VipText} onClick={onShowVipPromotion}>Get your messages read first</div>
            </>
        }
    </>
);

const VipPromotion = ({user, modalUser}) => {
    const dispatch = useDispatch();

    const gotoMembership = () => {
        dispatch(modalActions.open({name: 'vipUpgrade'}));
    }

    return (
    <div className={classes.Area}>
        <div className={classes.CenterText}>{modalUser.name} has other unread<br />messages in her inbox.</div>
        <div className={classes.VipUser}>
            <div className={classes.LeftSide}>
                <img src={aggPhoto(user.photo, 100, user)} className={classes.UserImg} alt="Audrius" />
                <div className={classes.VipArea}><img src={vipIcon} alt="vip user" /></div>
            </div>
            <div className={classes.RightSide}>
                <div className={classes.VipName}>{user.name}</div>
                <div className={classes.MessageExample}>Hi, how are you?</div>
            </div>
        </div>
        <div className={classes.ConversationBackground}><img src={imgVerificationBack} alt="" /></div>
        <div className={classes.ConversationBackground}><img src={imgVerificationBack} alt="" /></div>
        <div className={classes.ConversationBackground}><img src={imgVerificationBack} alt="" /></div>
        <div className={classes.BigText}>Would you like to be read first?</div>
        <button onClick={gotoMembership} className={`ButtonRounded ${classes.DiamondButton}`}><b>Upgrade to VIP Traveller</b></button>
    </div>
    );
};

export default SendMessage;
