import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import classes from './AddTrip.module.scss';
import Autocomplete from "../UI/Autocomplete/Autocomplete";
import Loader from "../UI/Loader/Loader";
import axios from "../../config/Axios";
import { myTripsActions } from "../../store/mytrips";
import { refetchUser } from "../../store/auth";

const AddTrip = (props) => {
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);

    const [tripStartDate, setTripStartDate] = useState(null);
    const [tripEndDate, setTripEndDate] = useState(null);
    const [tripLocation, setTripLocation] = useState(null);

    const [locationError, setLocationError] = useState(null);
    const [dateStartError, setDateStartError] = useState(null);
    const [dateToError, setDateToError] = useState(null);

    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
    let yyyy = today.getFullYear();
    if(dd<10){
        dd='0'+dd
    }
    if(mm<10){
        mm='0'+mm
    }
    today = yyyy+'-'+mm+'-'+dd;

    const onLocationSelect = (event) => {
        setTripLocation(event.locationId);
        setLocationError(null);
    }

    const locationChangeHandler = (value) => {
        if (value.length === 0) {
            setTripLocation(null);
            setLocationError('Trip location is required');
        }
    }

    const tripStartDateHandler = (event) => {
        setTripStartDate(event.target.value);
        setDateStartError(null);
    }

    const tripEndDateHandler = (event) => {
        setTripEndDate(event.target.value);
        setDateToError(null);
    }

    const addTripHandler = (event) => {
        event.preventDefault();

        if (locationError)  setLocationError(null);
        if (dateStartError) setDateStartError(null);
        if (dateToError)    setDateToError(null);
        if (errorMsg)       setErrorMsg(null);

        if (!tripLocation){
            setLocationError('Trip location is required');
        }
        if (!tripStartDate) {
            setDateStartError('Trip date is required');
        }
        if (!tripEndDate) {
            setDateToError('Trip date is required');
        }

        if (!tripLocation || !tripStartDate || !tripEndDate || tripStartDate > tripEndDate) {
            return;
        }

        const data = {
            date: {from: tripStartDate, to: tripEndDate},
            description: null,
            location: {locationId: tripLocation}
        };
        setLoading(true);

        axios.post('trips/create/'+user.id, data)
            .then((r)=> {
                setSuccessMsg(true);
                dispatch(myTripsActions.add({trip: r.data}));
                setTimeout(() => {props.modalClosed();}, 2000);
                setTimeout(() => {dispatch(refetchUser(user.id))}, 3000);
                setLoading(false);
            }).catch( error => {
                let errText = 'Something went wrong. Please try again.';
                if (error.response.data.error === 'trip-to-homelocation')        errText = 'Can not create a trip to your city.';
                if (error.response.data.error === 'trip-exists')                 errText = 'Trip to this location already exists.';
                if (error.response.data.error === 'has-not-payed-limit-reached') errText = 'Trips limit reached.';
                if (error.response.data.error === 'max-trip-limit-reached')      errText = 'Trips limit reached.';

                setErrorMsg(errText);
                setLoading(false);
        });
    }

    return (
        <div className={classes.AddTrip}>
            <h1>Create a trip</h1>
            <div className={classes.Introduction}>Where do you want to travel?</div>

            <form className={classes.LoginForm} onSubmit={addTripHandler}>
                <div className={classes.LocationRow}>
                    <div className={classes.label}>
                        Location:
                    </div>
                    <div className={`${classes.InputContainer}  ${locationError ? classes.Errored : ''} `}>
                        <Autocomplete
                            onLocationSelect={onLocationSelect}
                            onInputChange={locationChangeHandler}
                            className={classes.addTripAutocomplete}
                        />
                        <div className={classes.InputIcon}><i className="tg-icon icon-location"></i></div>
                    </div>
                    {locationError ? <div className={classes.Error}>{locationError}</div> : null}
                </div>

                <div className={classes.Introduction}>When do you plan to travel?</div>

                <div className={classes.DateRow}>
                    <div className={classes.DateField}>
                        <div className={classes.label}>
                            Date from:
                        </div>
                        <div className={`${classes.InputContainer}  ${dateStartError ? classes.Errored : ''} `}>
                            <input type="date" onChange={tripStartDateHandler}  min={today} name="date-from" />
                        </div>
                        {dateStartError ? <div className={classes.Error}>{dateStartError}</div> : null}
                    </div>

                    <div className={classes.DateField}>
                        <div className={classes.label}>
                            Date to:
                        </div>
                        <div className={`${classes.InputContainer}  ${dateToError ? classes.Errored : ''} `}>
                            <input type="date" onChange={tripEndDateHandler} min={ tripStartDate ? tripStartDate : today} name="date-to" />
                        </div>
                        {dateToError ? <div className={classes.Error}>{dateToError}</div> : null}
                    </div>
                </div>

                <div className={classes.SaveArea}>
                    {isLoading && <Loader />}
                    {!isLoading && !successMsg && <button type="submit" className={classes.Save} >Add trip</button>}
                    {successMsg && <div className={classes.SuccessMessage}>Trip was successfully created!</div>}
                    {errorMsg && !successMsg && !isLoading && <div className={classes.Error}>{errorMsg}</div>}
                </div>
            </form>
        </div>
    );
}

export default AddTrip;
